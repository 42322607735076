
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  ChekArray: boolean[] = [true, true, true, true, true, true, true, true, true]

  setChek (index) {
    this.ChekArray = this.ChekArray.map((item, ind) => {
      if (index === ind) {
        item = !item
      }
      return item
    })
  }
}
