
import Vue from 'vue'
import Component from 'vue-class-component'
import openPdf from '@pp-frontend/ui-kit/components/common/openPdf.vue'

@Component({
  components: {
  openPdf
  }
  })
export default class SearchFormOfertaText extends Vue {

}
